import React, { useState } from 'react';

function PartnerRegistration() {
  const [walletAddress, setWalletAddress] = useState('');
  const [registrationStatus, setRegistrationStatus] = useState('');
  const [partnerReferralLink, setPartnerReferralLink] = useState('');
  const [dashboardLink, setDashboardLink] = useState('');

  const connectWallet = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setWalletAddress(accounts[0]);
      } catch (error) {
        console.error('MetaMask connection failed:', error);
        setRegistrationStatus('MetaMask connection failed. Please try again.');
      }
    } else {
      setRegistrationStatus('MetaMask is not installed. Please install MetaMask to continue.');
    }
  };

  const registerPartner = async () => {
    if (!walletAddress) {
      setRegistrationStatus('Please connect your wallet first.');
      return;
    }

    try {
      // Sende Wallet-Adresse an das Backend
      const response = await fetch('https://api.achim.group/api/partner/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ walletAddress }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to register partner.');
      }

      const data = await response.json();
      setPartnerReferralLink(data.partnerReferralLink);
      setDashboardLink(data.dashboardLink);
      setRegistrationStatus('Registration successful!');
    } catch (error) {
      console.error('Registration failed:', error);
      setRegistrationStatus('Registration failed. Please try again.');
    }
  };

  return (
    <div>
      <h1>Partner Registration</h1>
      <button onClick={connectWallet}>Connect Wallet with MetaMask</button>
      {walletAddress && <p>Connected Wallet: {walletAddress}</p>}
      <button onClick={registerPartner}>Register as Partner</button>
      {registrationStatus && <p>{registrationStatus}</p>}
      {partnerReferralLink && (
        <p>
          Your referral link: <a href={partnerReferralLink}>{partnerReferralLink}</a>
        </p>
      )}
      {dashboardLink && (
        <p>
          Go to your dashboard: <a href={dashboardLink}>{dashboardLink}</a>
        </p>
      )}
    </div>
  );
}

export default PartnerRegistration;
